/**
 * Types of captial members in a portfolio company.
 */

export enum CapitalMemberType {
  MainFund = 'Main Fund',
  Coinvestment = 'Co-investment',
  
  /**
   * For 'fund of one' style investors, etc. where
   * the investor is not really a quantum fund
   */
  DirectCoinvestment = 'Direct Co-investment',

  /**
   *  Management members of the portfolio company
   */
  Management = 'Management',

  /**
   * Deprecated, but is in the database already
   */
  PoolingEntity = 'PoolingEntity',

  /**
   * For funds that re-capitalize a PC in a 
   * continuation vehicle.
   */
  ContinuationFund = 'Continuation Fund',

  Other = 'Other',
}
