export enum AppRoute {
  Admin = 'admin',
  Companies = 'companies',
  Treasury = 'treasury',
  IR = 'ir',
  Portfolio = 'portfolio',
  Funds = 'funds',
  Unauthorized = 'unauthorized',
  User = 'user',
  ReleaseNotes = 'releasenotes',
  Documents = 'documents'
}
