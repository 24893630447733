import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from './core/auth/msal/msal.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AppRoute } from './core/enums/app-route.enum';
import { CanDeactivateGuard } from './services/common/can-deactivate-guard.service';
import { UserResolver } from './user.resolver';

const routes: Routes = [
    {
        path: AppRoute.Companies,
        loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
        canActivate: [MsalGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'prefix',
        resolve: { data: UserResolver }
    },
    {
        path: AppRoute.Treasury,
        loadChildren: () => import('./modules/treasury/treasury.module').then((m) => m.TreasuryModule),
        canActivate: [MsalGuard],
    },
    {
        path: AppRoute.IR,
        loadChildren: () => import('./modules/ir/ir.module').then((m) => m.IrModule),
        canActivate: [MsalGuard]
    },
    {
        path: AppRoute.Funds,
        loadChildren: () => import('./modules/funds/funds.module').then((m) => m.FundsModule),
        canActivate: [MsalGuard]
    },
    {
        path: AppRoute.Admin,
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [MsalGuard],
    },
    {
        path: AppRoute.User,
        loadChildren: () => import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
        canActivate: [MsalGuard],
    },
    {
        path: AppRoute.Portfolio,
        loadChildren: () => import('./modules/portfolio/portfolio.module').then((m) => m.PortfolioModule),
        canActivate: [MsalGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'prefix',
    },
    {
        path: AppRoute.Documents,
        loadChildren: () => import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
        canActivate: [MsalGuard],
        canDeactivate: [CanDeactivateGuard],
        pathMatch: 'prefix',
    },
    {
        path: '',
        redirectTo: AppRoute.Companies,
        pathMatch: 'full'
    },
    {
        path: AppRoute.ReleaseNotes,
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/releasenotes/releasenotes.module').then((m) => m.ReleasenotesModule),
    },
    {
        path: AppRoute.Unauthorized,
        component: MainLayoutComponent,
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
