/**
 * List of approval request type
 */
 export enum ApprovalRequestType {
    CapitalCall = 'Capital Call',
    Commitment = 'Commitment',
    Distribution = 'Distribution',
    Valuation = 'Valuation',
    Board_Materials = "Board_Materials",
    Review = "Review"
}