import { Component, Input, OnChanges } from '@angular/core';


interface DynamicMetricData {
  metric: string;
  group: string;
  [key: string]: any 
}
@Component({
  selector: 'app-variance-table',
  templateUrl: './variance-table.component.html',
  styleUrls: ['./variance-table.component.scss']
})

export class VarianceTableComponent implements OnChanges {
  @Input()
  public groupedVarianceData: DynamicMetricData[];

  @Input()
  public cols: string[] = [];
  tableData: any[] = [];  
  constructor() { }

  ngOnChanges(): void {
    this.prepareTableData();
  }

  
  private prepareTableData() {
    const rowDataMap = new Map<string, any>();
    this.groupedVarianceData.forEach(yearData => {
      yearData.sections.forEach(section => {
        if (!rowDataMap.has(section.displayName)) {
          rowDataMap.set(section.displayName, { displayName: section.displayName, group: section.group, displayNameStyles: section.displayNameStyles });
        }
        rowDataMap.get(section.displayName)[yearData.year] = section.value;
      });
    });

    this.tableData = Array.from(rowDataMap.values());
  }
  isEndOfGroup(index: number, rows: any[]): boolean {
    if (index === rows.length - 1) {
      return true; // Last row
    }
    const currentGroup = this.getGroupKey(rows[index]);
    const nextGroup = this.getGroupKey(rows[index + 1]);
    return currentGroup !== nextGroup;
  }

  getGroupKey(row: any): string {
    return row.group
  }

}
