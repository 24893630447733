import { Injectable } from '@angular/core';
import { IFundFamily } from '@common/models/IFundFamily';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FundFamilyService {
    constructor(private apiService: ApiService) {

    }

    public getAll(): Observable<IFundFamily[]> {
        return this.apiService.get('FundFamily');
    }
}
