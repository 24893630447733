import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface DynamicMetricData {
    metric: string;
    group: string;
    [key: string]: any // Allows any additional properties with keys of type string and values of type number or string
}

@Component({
    selector: 'app-forecast-table',
    templateUrl: './forecast-table.component.html',
    styleUrls: ['./forecast-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForecastTableComponent {

    @Input()
    public groupedForecastData: DynamicMetricData[];

    @Input()
    public cols: string[] = [];
}
