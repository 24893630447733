import { Injectable } from '@angular/core';

import { AppRoute } from '@app/core/enums/app-route.enum';
import { UserRole } from '@common/enums/UserRole';

const routes: { [key: string]: string[] } = {
    [UserRole.User]: [
        AppRoute.Companies,
        AppRoute.Treasury,
        AppRoute.IR,
        AppRoute.Funds,
        AppRoute.Documents
    ],
    [UserRole.Admin]: [
        AppRoute.Admin,
        AppRoute.Companies,
        AppRoute.Treasury,
        AppRoute.IR,
        AppRoute.Portfolio,
        //AppRoute.Funds,
        AppRoute.Documents
    ]
};

@Injectable({
    providedIn: 'root'
})
export class RoleRoutesService {
    public getAll(): any {
        return routes || {};
    }

    public getByRole(role: UserRole): string[] {
        return routes[role] || [];
    }

    public isRouteAvailableForUserRole(route: string, role: UserRole): boolean {
        return this.getByRole(role).includes(route);
    }
    public isUserInRole(userRoleArr: [UserRole], role: UserRole): boolean{
       return userRoleArr.includes(role)
    }
}
