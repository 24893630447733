<div class="">
    <div class="secondary-header mt-4 mb-2">Notes
        <i class="pi pi-plus-circle mx-2 plus-circle cursor" (click)="isNotesTypeVisible= true"></i>
    </div>
    <p-table *ngIf="comments && comments?.length > 0" class="table" [value]="comments" dataKey="_id" >
        <ng-template pTemplate="header">
            <tr>
                <th  scope="col" *ngFor="let col of columns" [class]="col.headerClass">
                    {{col.name}}
                    <!-- <p-sortIcon field="{{col.field}}"></p-sortIcon> -->
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-comment>
            <tr>
                <td>{{ comment?.user ? getUserName(comment?.user) : '-' }}</td>
                <td>{{ comment.source}}</td>
                <td>{{ comment.value }}</td>
                <td>{{ comment.noteType }}</td>
                <td>{{comment.createdAt | date: 'MM-dd-yyyy' : 'UTC' }}</td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="comments && comments.length < 1">
        <div>No notes found</div>
    </div>
    <p-dialog header="Add Note" [contentStyle]="{'overflow': 'visible'}" [(visible)]="isNotesTypeVisible" [modal]="true" *ngIf="isNotesTypeVisible" [style]="{ width: '25rem' }">
        <div class="flex justify-content-end flex-wrap card-container flex-gap mb-2" >
            <button pButton type="button" label="Save" (click)="onSave()" [ngClass]="{ 'isDisabled': notes === '' || notes === null }" class="p-button-primary" ></button>
            <button pButton type="button" label="Cancel" (click)="isNotesTypeVisible = false; notes = '' " class="p-button-secondary" ></button>
        </div>
        <div class="align-items-center mb-5">
            <textarea class="notes-textarea" pInputTextarea (input)="noteChange($event)" [(ngModel)]="notes" [ngModelOptions]="{standard: true}" ></textarea>
        </div>

    </p-dialog>
</div>
<app-toaster [toasterData]="toasterData"></app-toaster>