import { Component, Input, OnInit } from '@angular/core';
import { QepFileBrowserService } from '@app/modules/documents/services/qep-file-browser.service';
import { IRepositoryItem } from '@common/models/IRepositoryItem';
import { FileBrowserRawData, QEPFileUploadEvent } from 'qep-file-browser/lib/qep-file-browser-events';
import { IToasterData } from '@common/models/IToasterData';
import { IAttachment } from '@common/models/IAttachment';
import { ConfirmationService } from 'primeng/api';
import { ApprovalRequestType } from '@common/enums/ApprovalRequestType';
import { IFundFamily } from '@common/models/IFundFamily';
import { ICapitalMember } from '@common/models/ICapitalMember';
import { CapitalMemberType } from '@common/enums/CapitalMemberType';
import { IPortfolioCompany } from '@common/models/IPortfolioCompany';
import { forkJoin, Observable } from 'rxjs';
import { FundFamilyService } from '@app/modules/companies/services/fund-family.service';
import { IStorageStat } from '@common/models/IStorageStat';

interface TreeNode<T = any> {
  label?: string;
  data?: T;
  icon?: string;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: TreeNode<T>[];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: TreeNode<T>;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;
  key?: string;
}

@Component({
  selector: 'app-document-repository',
  templateUrl: './document-repository.component.html',
  styleUrls: ['./document-repository.component.scss'],
  providers: [ConfirmationService]
})
export class DocumentRepositoryComponent implements OnInit {

  @Input() fundsRequired = true;
  @Input() rawFiles: any[];
  @Input() showCompany = false;
  toasterData: IToasterData = {} as any;
  files: any[] = [];
  mimeTypes = {
    'pdf': 'application/pdf',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'txt': 'text/plain',
    'csv': 'text/csv',
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg'
  };
  funds: IFundFamily[] = [];
  portfolioCompanies: IPortfolioCompany[] = [];
  responseReceived = false;
  expandedKeys: string[] = [];

  private mnemonicToCompany: { [x: string]: IPortfolioCompany } = {};
  private mnemonicDetails: {
    [x: string]: {
      writeUpFunds: ICapitalMember[];
      fundFamily: string[];
      companyName: string;
    }
  } = {};
  private readonly WRITEUP_CAPITAL_FUNDS = [CapitalMemberType.MainFund, CapitalMemberType.Coinvestment];
  versionHistory: undefined | {
    value: IStorageStat[];
    attachment: any;
    event: FileBrowserRawData
  };
  breadcrumbVisible = false;

  constructor(
    private qepFileBrowserService: QepFileBrowserService,
    private confirmationService: ConfirmationService,
    private fundFamilyService: FundFamilyService
  ) { }

  ngOnInit(): void {
    this.sortFilesByQuarter();
    const apiCalls: Observable<any>[] = [
      this.qepFileBrowserService.getAllPorfolioCompanies()
    ];
    if (this.fundsRequired) {
      apiCalls.push(this.fundFamilyService.getAll())
    }
    forkJoin(apiCalls)
      .subscribe(([res1, res2]) => {
        this.portfolioCompanies = res1;
        this.funds = res2 || [];
        this.prepareFiles();
        this.responseReceived = true;
      })
  }

  sortFilesByQuarter() {
    this.rawFiles?.sort((a, b) => {
      const [yearA, quarterA] = a.storageStat.path.split('/')[2].split('-Q').map(Number);  // Split and parse year and quarter for `a`
      const [yearB, quarterB] = b.storageStat.path.split('/')[2].split('-Q').map(Number);  // Split and parse year and quarter for `b`

      // Compare years first
      if (yearA !== yearB) {
        return yearB - yearA;  // Descending order for years
      }

      // If years are the same, compare quarters
      return quarterB - quarterA;  // Descending order for quarters
    });
  }

  getMnemonicDetails(mnemonic: string): { companyName: string; fundFamily: string[]; writeUpFunds: ICapitalMember[] } {
    if (this.mnemonicDetails[mnemonic]) {
      return this.mnemonicDetails[mnemonic];
    }
    const company = this.mnemonicToCompany[mnemonic] || this.portfolioCompanies.find(c => c.mnemonic === mnemonic);
    if (!this.mnemonicToCompany[mnemonic]) {
      this.mnemonicToCompany[mnemonic] = company;
    };
    const companyName = company?.name || mnemonic;
    const fundFamily = company?.fundFamily || [];
    const writeUpFunds = company.capitalMembers?.filter(cm => this.WRITEUP_CAPITAL_FUNDS.includes(cm.memberType)) || [];
    const obj = { companyName, fundFamily, writeUpFunds };
    this.mnemonicDetails[mnemonic] = JSON.parse(JSON.stringify(obj));
    return this.mnemonicDetails[mnemonic];
  }

  private isLeaf(index: number, count: number) { return index === count - 1; };

  private buildTree(nodes: any[]): TreeNode {
    const root: TreeNode = { key: '', label: 'Root', children: [] };

    nodes.forEach(node => {
      const parts = node.storageStat.path.split('/');
      const count = parts.length;
      let current = root;
      parts.forEach((part: string, index: number) => {
        // Skip empty parts from leading slashes
        if (!part) return;
        // Build the full key for the current path segment
        const key = current.key + '/' + part;
        // Check if the part exists already in the children
        let child = current.children?.find(c => c.key === key);
        const mnemonic = node.properties.mnemonic;
        const { companyName, fundFamily, writeUpFunds } = this.getMnemonicDetails(mnemonic);
        if (!child) {
          child = {
            key,
            data: {
              name: part,
              size: 0,
              type: "Folder",
              icon: 'pi pi-fw pi-folder',
              mtime: node.storageStat.mtime,
              lastModifiedBy: node.storageStat.lastModifiedBy,
              documentType: 'File Folder',
              activityName: node.properties.activityName,
              path: node.storageStat.path,
              mnemonic,
              investmentActivity: node.properties.investmentActivity,
              companyName,
              fundFamily,
              writeUpFunds
            },
            children: [],
            expanded: this.expandedKeys.includes(key)
          };
          current.children?.push(child);
        };

        current = child;

        if (this.isLeaf(index, count)) {
          child.children?.push({
            key,
            data: {
              name: node.storageStat.name,
              size: node.storageStat.size,
              type: 'File',
              icon: 'pi pi-fw pi-file',
              mtime: node.storageStat.mtime,
              lastModifiedBy: node.storageStat.lastModifiedBy,
              isLeaf: true,
              documentType: node.properties.documentType,
              activityName: node.properties.activityName,
              path: node.storageStat.path,
              mnemonic,
              investmentActivity: node.properties.investmentActivity,
              companyName,
              fundFamily,
              writeUpFunds
            }
          } as never);
        }
        child.data.size += node.storageStat.size;
      });
    });
    return root;
  }

  private prepareFiles() {
    const data: IRepositoryItem[] = JSON.parse(JSON.stringify(this.rawFiles));
    const root = this.buildTree(data);
    const files = root.children || [];
    if (this.showCompany) {
      files.sort((a, b) => a.data.companyName.localeCompare(b.data.companyName));
      files.forEach(file => file.children = this.sortInvestmentActivity(file.children));
      this.files = files;
    } else {
      this.files = this.sortInvestmentActivity(files[0].children);
    }
  }

  deleteFile(ev: any) {
    this.confirmationService.confirm({
      message: 'This will delete the selected file. Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const date = ev.investmentActivity === ApprovalRequestType.Valuation ? null : ev.name.split('_')[2];
        this.qepFileBrowserService.deleteFile(ev.mnemonic, ev.activityName, date, ev.investmentActivity, ev.documentType, 0, ev.name).subscribe({
          next: d => {
            this.toasterData = {
              message: d.message,
              status: 'success'
            }
            this.rawFiles = this.rawFiles.filter(rf => !(rf.storageStat.name === ev.name && rf.storageStat.path === ev.path));
            this.prepareFiles();
          },
          error: error => {
            this.toasterData = {
              message: error.message,
              status: 'error'
            }
          }
        })
      },
      reject: () => {
        console.log('Submit cancelled');
      },
    });
  }

  downloadFile(ev: FileBrowserRawData) {
    this.qepFileBrowserService.downloadFile(ev.name, ev.path).subscribe({
      next: data => {
        let file = new Blob([data], { type: 'application/json' });
        const fileName = ev.name;
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(fileURL);
        setTimeout(() => {
          this.toasterData = {
            message: `File ${ev.name} downloaded successfully.`,
            status: "success"
          }
        }, 100);
      },
      error: err => {
        this.toasterData = {
          message: `File ${ev.name} could not be downloaded.`,
          status: "error"
        }
      }
    })
  }

  downloadFiles(ev: FileBrowserRawData) {
    this.qepFileBrowserService.downloadFiles(ev.downloadPath.substring(1), ev.mnemonic).subscribe({
      next: data => {
        let file = new Blob([data], { type: 'application/zip' });
        const fileName = "documents.zip";
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(fileURL);
        setTimeout(() => {
          this.toasterData = {
            message: `Files downloaded successfully.`,
            status: "success"
          }
        }, 100);
      },
      error: () => {
        this.toasterData = {
          message: `Files could not be downloaded.`,
          status: "error"
        }
      }
    })
  }

  async completeUpload(ev: QEPFileUploadEvent) {
    this.qepFileBrowserService.uploadFile(ev).subscribe({
      next: (res) => {
        const { mnemonic, investmentActivity, activityName, documentType } = ev.rawData;
        const { fileName, path, creationDate, lastModifiedDate, createdBy, lastModifiedBy } = res
        const data = {
          storageStat: {
            name: fileName,
            path,
            size: 0,
            isDirectory: false,
            isFile: true,
            birthtime: creationDate,
            mtime: lastModifiedDate,
            createdBy,
            lastModifiedBy
          },
          properties: {
            mnemonic,
            investmentActivity,
            activityName,
            documentType
          }
        }
        if (ev.originalFile) {
          const existingFileIndex = this.rawFiles.findIndex(rf => rf.storageStat.name === fileName);
          this.rawFiles[existingFileIndex] = data;
        } else {
          this.rawFiles.push(data);
        };
        this.prepareFiles();
        this.toasterData = {
          message: `File ${ev.files[0].name} uploaded successfully.`,
          status: "success"
        };
      },
      error: (error: any) => {
        this.toasterData = {
          message: error.message || "A problem occurred while uploading documents.",
          status: "error"
        };
      }
    })
  }

  uploadFile(ev: QEPFileUploadEvent) {
    if (ev.originalFile) {
      this.confirmationService.confirm({
        message: `This will replace existing document ${ev.originalFile}. Are you sure that you want to proceed?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.completeUpload(ev)
        },
        reject: () => {
          console.log('Submit cancelled');
        },
      });
    } else {
      this.completeUpload(ev);
    }
  }

  viewFile(ev: FileBrowserRawData) {
    const ext = ev.name.split('.').pop().toLowerCase();
    const attachment: IAttachment = {
      fileName: ev.name,
      path: ev.path,
      mimeType: this.mimeTypes[ext],
      index: 0,
      attachmentType: ev.documentType
    };

    this.qepFileBrowserService.previewFile(attachment).subscribe({
      next: value => {
        const file = new Blob([value], { type: attachment.mimeType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "quilt_preview", "width=800,height=1024,popup=yes,scrollbars=yes,resizable=yes");
      },
      error: (err) => {
        this.toasterData = {
          message: `File preview failed.`,
          status: "error"
        }
      }
    })
  }

  viewVersionHistory(ev: FileBrowserRawData) {
    const ext = ev.name.split('.').pop().toLowerCase();
    const attachment: IAttachment = {
      fileName: ev.name,
      path: ev.path,
      mimeType: this.mimeTypes[ext],
      index: 0,
      attachmentType: ev.documentType,
      lastModifiedBy: ev.lastModifiedBy
    };
    this.qepFileBrowserService.getVersionAttachmentList(ev.mnemonic, ev.investmentActivity, ev.name, ev.activityName, this.getDate(ev))
      .subscribe(d => {
        this.versionHistory = {
          value: d,
          event: ev,
          attachment
        };
      })
  }

  downloadVersionTemplate(event) {
    const attachment = event.attachment;
    const version = event.version;
    const { mnemonic, investmentActivity, activityName } = this.versionHistory.event;
    this.qepFileBrowserService.downloadAttachmentVersion(mnemonic, investmentActivity, activityName, attachment, version, this.getDate(this.versionHistory.event))
      .subscribe({
        next: (value) => {
          const file = new Blob([value], { type: attachment.mimeType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "attachment_verion_history", "width=800,height=1024,popup=yes,scrollbars=yes,resizable=yes");
        }, error: (err) => {
          console.log(err)
        }
      })
  }

  toggleBreadCrumb(isShowing: boolean) {
    this.breadcrumbVisible = isShowing;
  }

  private getDate(ev: any): string {
    let date = ''
    if (ev.investmentActivity === ApprovalRequestType.Board_Materials) {
      date = ev.name.split('.')[0].split('_').pop();
    }
    return date;
  }

  private sortInvestmentActivity(nodes: TreeNode[]) {
    // Define the desired order
    const order = [ApprovalRequestType.Board_Materials, ApprovalRequestType.Valuation, ApprovalRequestType.Review];

    // Use a custom comparator function in sort()
    return nodes.sort((a, b) => {
      return order.indexOf(a.data.name) - order.indexOf(b.data.name);
    });
  }
}
