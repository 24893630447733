import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgxPowerBiModule } from 'ngx-powerbi';
import {
    NgxBootstrapIconsModule,
    personCircle, list, listUl, x, search
} from 'ngx-bootstrap-icons';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChartModule } from 'primeng/chart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SplitterModule } from 'primeng/splitter';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { FileUploadModule } from 'primeng/fileupload';
import { StepsModule } from 'primeng/steps';
import { MenubarModule } from 'primeng/menubar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { EditorModule } from 'primeng/editor';
import {InputMaskModule} from 'primeng/inputmask';
import { TabMenuModule } from 'primeng/tabmenu';
import {ContextMenuModule} from 'primeng/contextmenu';
import { SelectButtonModule } from 'primeng/selectbutton';

import { ReportsNavComponent } from '@app/shared/features/reports/components/reports-nav/reports-nav.component';
import { ReportsPageComponent } from '@app/shared/features/reports/components/reports-page/reports-page.component';

import { LetDirective } from './directives/let.directive';
import { ClickDocumentDirective } from './directives/click-document.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { AvailableForDirective } from './directives/available-for.directive';
import { IfAuthorizedDirective } from './directives/if-authorized.directive';

import { ArrowComponent } from './components/arrow/arrow.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PowerBiComponent } from './components/power-bi/power-bi.component';
import { TableComponent } from './components/table';

import { KeyValuePipe } from './pipes/key-value.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { TableToolbarComponent } from './components/table-toolbar/table-toolbar.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CompanyHeaderComponent } from './components/company-header/company-header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DisableIfFormControlDirective } from './directives/disable-if';
import { UserChipComponent } from './components/user-chip/user-chip.component';
import { UserChipsComponent } from './components/user-chips/user-chips.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './Interceptors/SpinnerInterceptor';
import { ErrorInterceptor } from '@app/interceptors/error.interceptor';
import { MessageService } from 'primeng/api';
import { CommentsComponent } from './components/comments/comments.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { DocumentRepositoryComponent } from './components/document-repository/document-repository.component';
import { QepFileBrowserModule } from 'qep-file-browser';
import { ForecastTableComponent } from './components/forecast-table/forecast-table.component';
import { VarianceTableComponent } from './components/variance-table/variance-table.component';

const directives = [
    LetDirective,
    ClickDocumentDirective,
    ScrollToDirective,
    AvailableForDirective,
    IfAuthorizedDirective,
    DisableIfFormControlDirective
];

const components = [
    ArrowComponent,
    DropdownComponent,
    PageHeaderComponent,
    PowerBiComponent,
    ReportsNavComponent,
    ReportsPageComponent,
    TableComponent,
    TableToolbarComponent,
    ConfirmDialogComponent,
    ToasterComponent,
    FileUploadComponent,
    LoaderComponent, 
    UserChipComponent,
    UserChipsComponent,
    CommentsComponent,
    VersionHistoryComponent,
    DocumentRepositoryComponent,
    ForecastTableComponent,
    VarianceTableComponent
];

const pipes = [
    KeyValuePipe,
    UnitPipe,
];

const modules = [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    MenuModule,
    TableModule,
    TabViewModule,
    TabMenuModule,
    DialogModule,
    TooltipModule,
    ToolbarModule,
    SidebarModule,
    SplitterModule,
    ButtonModule,
    CalendarModule,
    RadioButtonModule,
    DividerModule,
    CardModule,
    CheckboxModule,
    InputTextareaModule,
    InputNumberModule,
    ChartModule,
    ChipsModule,
    ChipModule,
    ConfirmDialogModule,
    MessagesModule,
    ToastModule,
    RippleModule,
    FileUploadModule,
    StepsModule,
    MenubarModule,
    ProgressSpinnerModule,
    EditorModule,
    InputMaskModule,
    ContextMenuModule,
    QepFileBrowserModule,
    SelectButtonModule
];

@NgModule({
    declarations: [
        ...directives,
        ...pipes,
        ...components,
        CompanyHeaderComponent,
        UserChipsComponent
    ],
    imports: [
        ...modules,
        NgxPowerBiModule,
        NgxBootstrapIconsModule.pick({
            personCircle, list, listUl, x, search
        })
    ],
    exports: [
        ...modules,
        NgxBootstrapIconsModule,

        ...directives,
        ...pipes,
        ...components,
        CompanyHeaderComponent
    ],
    providers: [
        MessageService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SpinnerInterceptor,
          multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ]
})
export class SharedModule { }
