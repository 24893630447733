<ul class="nav">
  <ng-container *ngFor="let item of menuItems">
    <li *availableFor="item.roles" class="nav__item">
      <a class="nav__link"
        [routerLink]="['/' + item.link]" 
        [routerLinkActive]="'active'" 
        [routerLinkActiveOptions]="{exact: item.exact}"
        [style.display] ="(item.isVisible ===undefined || item?.isVisible) ? 'block' : 'none'"
      >{{item.title}}</a>
    </li>
  </ng-container> 
</ul>
