<div class="secondary-header">Version History</div>
<br />
<div *ngIf="historyData !== null && historyData?.length> 0; else noDataFound">
    <p-table [value]="historyData" styleClass="p-datatable-sm pt-4">
        <ng-template pTemplate="header">
            <tr>
                <th>Time Stamp</th>
                <th>Name</th>   
                <th>Modified By</th>
                <th>Size</th>
                <th>
                    Download
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
            <tr>
                <td>{{history.mtime | date: 'MM/dd/yyyy, H:mm'}}</td>
                <td>{{history.name}}</td>
                <td>{{history.lastModifiedBy}}</td>
                <td>{{ formatBytes(history.size)}}</td>
                <th><em *ngIf="history" class="pi pi-download cursor-pointer" (click)="downloadVersionTemplate(history)"></em><em *ngIf="!history" >N/A</em></th>
            </tr>
        </ng-template>
    </p-table>
</div>
<ng-template #noDataFound>
    <span>No Data Found</span>
</ng-template>