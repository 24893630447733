import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) {}

    public get<T>(resourceUrl: string): Observable<T> {
        const url = this.baseUrl.concat(resourceUrl);

        return this.http.get<T>(url, {
            headers: new HttpHeaders().set('Accept', 'application/json')
        });
    }

    public getV2(resourceUrl: string, options: any = {
        headers: new HttpHeaders().set('Accept', 'application/json'),
    }): Observable<any> {
        const url = this.baseUrl.concat(resourceUrl);
    
        return this.http.get(url, options);
    }

    public post(resourceUrl: string, data: any, options?: any): Observable<any> {
        const url = this.baseUrl.concat(resourceUrl);

        return this.http.post(url, data, options);
    }

    public put(resourceUrl: string, data: any, options?: any): Observable<any> {
        const url = this.baseUrl.concat(resourceUrl);

        return this.http.put(url, data, options);
    }

    public delete(resourceUrl: string, options?: any): Observable<any> {
        const url = this.baseUrl.concat(resourceUrl);

        return this.http.delete(url, options);
    }
}
