import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private apiService: ApiService) { }
  get(mnemonic, activityType, name): Observable<any> {
    return this.apiService.get(`Note/${mnemonic}/${activityType}/${name}`);
  }
  add(requestBody): Observable<any>{
    return this.apiService.post(`Note`, requestBody);
  }
}
