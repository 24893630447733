import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) =>{
        let errorMsg = '' ;
        if(error.error instanceof ErrorEvent){
          // for client side error handling
          errorMsg = `Error: ${error.error.message}`;
        }
        else{
          // for server side error handling
          switch (error.status) {
            case 401:
              break;
            case 403:
              errorMsg = 'Forbidden';
              break;
            case 404:
              errorMsg = `Sorry can't find that !`;
              break;
            case 504:
              errorMsg = 'Session Timeout';
              break;
            case 500:
              errorMsg = 'Server Error';
              break;
            default:
              errorMsg = 'Unknown Error'
              //errorMsg =  error?.error ? error.error.message : error?.message ? error.message : error ;
              break;
          }
        }
        this.messageService.add({severity:'error', summary: error.statusText, detail: errorMsg});
        return throwError(() => error);
      })
      
    )
  }
}
